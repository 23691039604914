<template>
  <address-display
    v-model="internalValue"
    :dark="theme.website.cards.dark"
    :light="theme.website.cards.light"
    :color="theme.website.cards.color"
    hide-remove
    :loading="loading"
    @remove="onRemove"
    @update="onEdit"
  />
</template>

<script>
  import AddressDisplay from '@peynman/press-vue-admin/components/Inputs/AddressInput/AddressDisplay.vue'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'

  export default {
    name: 'ProfileAddressDisplay',
    components: {
      AddressDisplay,
    },
    mixins: [
      Themeable,
    ],
    props: {
      value: Object,
    },
    data: vm => ({
      loading: false,
      internalValue: {
        ...(vm.value ?? {}),
        location: vm.value?.data?.location,
      },
    }),
    watch: {
      value () {
        this.internalValue = {
          ...(this.value ?? {}),
          location: this.value?.data?.location,
        }
      },
    },
    methods: {
      onRemove () {
        this.loading = true
        this.$store.dispatch('profile/removeAddress', this.value.id)
          .finally(() => {
            this.loading = false
          })
      },
      onEdit (address) {
        this.loading = true
        this.$store.dispatch('profile/updateAddress', {
          ...address,
          id: this.value.id,
        })
          .then(json => {
            this.dialog = false
            this.internalValue = json
          })
          .catch(err => {
            this.$refs.form.updateFormValidationErrors(err)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>
