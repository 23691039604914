<template>
  <website>
    <div class="d-flex flex-column justify-center align-center my-5">
      <profile-details-card />
      <profile-addresses-card class="mt-5" />
    </div>
  </website>
</template>

<script>
  import Website from '../templates/Website/Website.vue'
  import ProfileDetailsCard from '../components/Profile/ProfileDetailsCard.vue'
  import ProfileAddressesCard from '../components/Profile/Addresses/ProfileAddressesCard.vue'

  export default {
    name: 'ModenaProfile',
    components: {
      Website,
      ProfileDetailsCard,
      ProfileAddressesCard,
    },
  }
</script>
