<template>
  <v-card
    :dark="theme.website.cards.dark"
    :light="theme.website.cards.light"
    :color="theme.website.cards.color"
    class="fill-width"
  >
    <v-toolbar>
      <v-toolbar-title>
        {{ $t('components.website.profileDetails.title') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <v-card-text>
      <validations-alert :type="formAlertType" :errors="formAlertErrorList" :message="formAlertMessage" />
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" sm="4">
            <image-upload-input
              v-model="lazyProfile"
              :label="$t('components.website.profileDetails.profilePic')"
              :image-quality="1"
              :image-width="128"
              :image-height="128"
              :image-max-scaling="2"
              image-min-scaling="cover"
              clearable
              full-width
              full-height
            />
          </v-col>
          <v-col cols="12" sm="8">
            <gender-input
              v-model="lazyGender"
              :label="$t('components.website.profileDetails.gender')"
              :rules="[getRequiredRule()]"
              :error-messages="getPropertyErrorMessages('gender')"
            />
            <v-text-field
              v-model="lazyFirstname"
              :label="$t('components.website.profileDetails.firstname')"
              :hint="$t('components.website.profileDetails.firstnameHint')"
              :rules="[getRequiredRule()]"
              :error-messages="getPropertyErrorMessages('firstname')"
              v-bind="theme.website.cards.inputs"
            />
            <v-text-field
              v-model="lazyLastname"
              :label="$t('components.website.profileDetails.lastname')"
              :hint="$t('components.website.profileDetails.lastnameHint')"
              :rules="[getRequiredRule()]"
              :error-messages="getPropertyErrorMessages('lastname')"
              v-bind="theme.website.cards.inputs"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions class="mx-2">
      <v-spacer />
      <v-btn
        :loading="loading"
        v-bind="theme.website.cards.buttons.submit"
        dark
        @click="onSubmit"
      >
        {{ $t('components.website.profileDetails.submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import ValidationsAlert from '@peynman/press-vue-admin/components/Inputs/ValidationsAlert/ValidationsAlert.vue'
  import ImageUploadInput from '@peynman/press-vue-admin/components/Inputs/ImageUploadInput/ImageUploadInput.vue'
  import GenderInput from '@peynman/press-vue-admin/components/Inputs/GenderInput/GenderInput.vue'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'
  import UserProfile from '@peynman/press-vue-core/mixins/UserProfile'

  export default {
    name: 'ProfileDetailsCard',
    components: {
      ValidationsAlert,
      ImageUploadInput,
      GenderInput,
    },
    mixins: [
      Themeable,
      UserProfile,
      FormValidations([]),
    ],
    data: vm => ({
      valid: true,
      loading: false,
      lazyFirstname: null,
      lazyLastname: null,
      lazyProfile: null,
      lazyGender: null,
    }),
    mounted () {
      this.lazyFirstname = this.firstname
      this.lazyLastname = this.lastname
      this.lazyProfile = this.userProfilePic
      this.lazyGender = this.gender
    },
    methods: {
      onSubmit () {
        this.$refs.form.validate()

        if (this.valid) {
          this.loading = true
          this.$store.dispatch('profile/updateDetails', {
            firstname: this.lazyFirstname,
            lastname: this.lazyLastname,
            profilePic: this.lazyProfile,
            gender: this.lazyGender,
          }).then(json => {
            this.updateFormSuccessMessage(json.message)
          }).catch(error => {
            this.updateFormValidationErrors(error)
          }).finally(() => {
            this.loading = false
          })
        }
      },
    },
  }
</script>
