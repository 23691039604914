<template>
  <v-card
    :dark="theme.website.cards.dark"
    :light="theme.website.cards.light"
    :color="theme.website.cards.color"
    class="fill-width"
  >
    <v-toolbar>
      <v-toolbar-title>
        <span>{{ $t('components.website.profileAddresses.title') }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-dialog
        :dark="theme.website.cards.dark"
        :light="theme.website.cards.light"
        :color="theme.website.cards.color"
      >
        <template v-slot:activator="{on}">
          <v-btn text v-on="on">
            <v-icon class="me-1">mdi-plus-box</v-icon>
            {{ $t('components.website.profileAddresses.addAddress') }}</v-btn>
        </template>
        <address-form
          :dark="theme.website.cards.dark"
          :light="theme.website.cards.light"
          :color="theme.website.cards.color"
          @input="onAddAddress"
        />
      </v-dialog>
    </v-toolbar>
    <v-divider />
    <v-card-text>
      <v-list>
        <div v-for="(address, index) in addresses" :key="`user-address-${index}`">
          <profile-address-display :value="address" />
          <v-divider />
        </div>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
  import AddressForm from '@peynman/press-vue-admin/components/Inputs/AddressInput/AddressForm.vue'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import UserProfile from '@peynman/press-vue-core/mixins/UserProfile'
  import ProfileAddressDisplay from './ProfileAddressDisplay.vue'
  import Constants from '../../../constants'

  export default {
    name: 'ProfileAddressesCard',
    components: {
      AddressForm,
      ProfileAddressDisplay,
    },
    mixins: [
      Themeable,
      UserProfile,
    ],
    methods: {
      onAddAddress (address) {
        this.$store.commit('profile/addAddress', address).then(address => {
          this.$store.dispatch('analytics/trackGoalConversion', Constants.Matomo.Goals.add_new_address)
        })
      },
    },
  }
</script>
